var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{attrs:{"title":"生产服务动态"}},[_c('div',{staticClass:"dynamic",on:{"click":function($event){return _vm.liClick($event)}}},[_c('vue-seamless-scroll',{attrs:{"data":_vm.newsList,"class-option":_vm.defaultOption}},[_c('ul',_vm._l((_vm.newsList),function(item,index){return _c('li',{key:index},[_c('ul',{staticClass:"sub"},_vm._l((item),function(w,i){return _c('li',{key:'sub' + i,staticClass:"truncate icon",attrs:{"title":w.areaName +
                w.farmerName +
                '与' +
                w.companyName +
                '完成' +
                w.landArea +
                '亩土地' +
                w.orderTypeName}},[_vm._v(" "+_vm._s(w.areaName)+_vm._s(w.sex ==2 ? w.farmerName.charAt(0)+'女士' :w.farmerName.charAt(0)+'先生')+"与"+_vm._s(w.companyName)+"完成"+_vm._s(w.landArea)+"亩土地"+_vm._s(w.orderTypeName)+" ")])}),0)])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }