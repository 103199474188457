<template>
  <card title="供求资讯">
    <leftDetail
      v-if="showDetail"
      @closeDialog1="closeDialog1"
      ref="showDetail"
    />
    <rightDetail
      v-if="showDetail2"
      @closeDialog2="closeDialog2"
      ref="showDetail2"
    />
    <div class="supply-demand">
      <div class="supply">
        <div class="title">
          <h5>托管服务商</h5>
          <span v-if="currentLeft < 5" @click="goMore" class="more"
          
            >更多 ></span
          >
        </div>
        <!-- <ul class="new-type">
          <li
            v-for="item of leftTypeList"
            :key="item.code"
            :class="item.code == currentLeft ? 'active' : ''"
            @click="currentLeft = item.code"
          >
            {{ item.name }}
          </li>
        </ul> -->
        <div class="new-des" style="margin-top: 22px">
          <!-- 托管 -->
          <ul v-if="currentLeft == 1">
            <li v-for="item in supplyData" :key="item.code">
              <div class="new-date">
                <span>{{ item.dayNum }}</span>
                <p>{{ item.yearNum }}.{{ item.monthNum }}</p>
              </div>
              <div
                class="new-title"
                @click="detailClick(item)"
                :title="item.title"
              >
                <span>{{ item.title }}</span>
                <p class="des">
                  <span>项目来源：{{ item.companyName }}</span>
                </p>
              </div>
            </li>
          </ul>
          <!-- 农资 -->
          <!-- <ul v-if="currentLeft == 2">
            <li v-for="item of supplyData" :key="item.code">
              <div class="new-date">
                <span>{{ item.dayNum }}</span>
                <p>{{ item.yearNum }}.{{ item.monthNum }}</p>
              </div>
              <div class="new-title" @click="showDetails(item, 2)">
                <span>{{ item.address }}--{{ item.businessTypeName }}</span>
                <p class="des">
                  编号：{{ item.registrationCode }}
                  <span>来源：{{ item.legalRepresentative }}</span>
                </p>
              </div>
            </li>
          </ul> -->
          <!-- 农服 -->
          <!-- <ul v-if="currentLeft == 3">
            <li v-for="item of supplyData" :key="item.code">
              <div class="new-title" @click="showDetail(item, 3)">
                <span>{{ item.busName }}</span>
                <p class="des">
                  编号：{{ item.registrationCode }}
                  <span>来源：{{ item.legalPeople }}</span>
                </p>
              </div>
            </li>
          </ul> -->
          <!-- 农技 -->
          <!-- <ul v-if="currentLeft == 4">
            <li v-for="item of supplyData" :key="item.code">
              <div class="new-date">
                <span>{{ item.dayNum }}</span>
                <p>{{ item.yearNum }}.{{ item.monthNum }}</p>
              </div>
              <div class="new-title" @click="showDetails(item, 4)">
                <span>{{ item.agriculturaName }}</span>
                <p class="des">
                  联系地址：{{ item.address }}
                  <span>联系方式：{{ item.phone }}</span>
                </p>
              </div>
            </li>
          </ul> -->
          <!-- 其他 -->
          <span class="workStyle" v-if="currentLeft == 5">
            正在努力开发中......
          </span>
        </div>
      </div>
      <div class="supply demand">
        <div class="title">
          <h5>托管地块</h5>
          <span class="more" v-if="rightMore" @click="goMoreRight">更多 ></span>
        </div>
        <ul class="new-type">
          <!-- <li class="active" @click="currentRight = 1">
            托管
          </li> -->
        </ul>
        <div class="new-des">
          <!-- 托管 -->
          <ul v-if="currentRight == 1">
            <li v-for="item of purcharseData" :key="item.code">
              <div class="new-date">
                <span>{{ item.dayNum }}</span>
                <p>{{ item.yearNum }}.{{ item.monthNum }}</p>
              </div>
              <div class="new-title" @click="detailClick2(item)">
                <span
                  >{{ item.areaName }}{{ item.orderTypeName }}{{ item.cropCode
                  }}{{ item.landArea }}亩</span
                >
                <p class="des">
                  项目编号：{{ item.orderCode }}
                  <span
                    >信息来源：{{
                      item.farmerName.substr(0, 1) +
                      (item.sex == "2" ? "女士" : "先生")
                    }}</span
                  >
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <el-dialog title="托管详情" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" label-width="140px">
        <el-form-item label="项目编号:">
          {{ currentItem.uscCode }}
        </el-form-item>
        <el-form-item label="服务范围:">
          {{ currentItem.serviceScope }}
        </el-form-item>
        <el-form-item label="农事类型:">
          {{ currentItem.operScope }}
        </el-form-item>
        <el-form-item label="托管类型:">
          {{ currentItem.serviceType == 1 ? "全程托管" : "环节托管" }}
        </el-form-item>

        <el-form-item label="联系人:">
          {{ currentItem.contactMan }}
        </el-form-item>
        <el-form-item label="联系电话:"> {{ currentItem.phone }} </el-form-item>

        <el-form-item label="托管价格:">
          {{ currentItem.servicePrice }} 元/亩</el-form-item
        >
        <el-form-item label="托管土地面积:">
          {{ currentItem.serviceArea }} 亩</el-form-item
        >
        <el-form-item label="地址:"> {{ currentItem.address }} </el-form-item>
        <el-form-item label="缩略图:">
          <img class="img" :src="globalUrl + currentItem.uscUrl" />
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="农资详情" :visible.sync="otherVisible" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="项目编号:">
          {{ currentItem.registrationCode }}
        </el-form-item>
        <el-form-item label="名称:"> {{ currentItem.name }} </el-form-item>
        <el-form-item label="联系电话:"> {{ currentItem.phone }} </el-form-item>
        <el-form-item label="地址:"> {{ currentItem.address }} </el-form-item>
        <el-form-item label="经营范围:">
          {{ currentItem.businessRange }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="农服详情" :visible.sync="serveVisible" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="项目编号:">
          {{ currentItem.registration_code }}
        </el-form-item>
        <el-form-item label="名称:"> {{ currentItem.busName }} </el-form-item>
        <el-form-item label="法人:">
          {{ currentItem.legalPeople }}
        </el-form-item>
        <el-form-item label="联系电话:"> {{ currentItem.phone }} </el-form-item>
        <el-form-item label="地址:">
          {{ currentItem.busAddress }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="农技详情" :visible.sync="agriVisible" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="站点名称:">
          {{ currentItem.agriculturaName }}
        </el-form-item>
        <el-form-item label="联系电话:"> {{ currentItem.phone }} </el-form-item>
        <el-form-item label="地址:"> {{ currentItem.address }} </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="托管详情" :visible.sync="demandVisible" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="项目编号:">
          {{ currentItem.orderCode }}</el-form-item
        >
        <el-form-item label="需求方:">
          {{ currentItem.farmerName }}
        </el-form-item>
        <el-form-item label="托管类型:">
          {{ currentItem.orderType == 1 ? "全程托管" : "环节托管" }}
        </el-form-item>

        <el-form-item label="需求列表:">
          <ul>
            <li v-for="item of currentItem.orderPriceList" :key="item.itemId">
              {{ item.itemName }}: {{ item.minTotalPrice }} ~
              {{ item.maxTotalPrice }}元
            </li>
          </ul>
        </el-form-item>

        <el-form-item label="土地面积:">
          {{ currentItem.landArea }} 亩</el-form-item
        >
        <el-form-item label="地址:"> {{ currentItem.areaCode }} </el-form-item>
        <el-form-item label="产权凭证照片:">
          <img
            class="img"
            :src="globalUrl + currentItem.landCertificateUrl"
            alt="产权凭证照片"
          />
        </el-form-item>
      </el-form>
    </el-dialog>
  </card>
</template>


<script>
import leftDetail from "./SupplyTgLeftDetail.vue";
import rightDetail from "./SupplyTgRightDetail.vue";
import Card from "@/components/Card/index.vue";

import {
  getSupplyInfoOfMaterials,
  getSupplyInfoOfService,
  getSupplyInfoOfTechnology,
} from "@/api/supplyDemand.js";
import { getCompanyList, getOrderList } from "@/api/projectManage";
import { mapGetters } from "vuex";
const leftTypeList = [
  {
    code: 1,
    name: "托管",
  },
  {
    code: 2,
    name: "农资",
  },
  {
    code: 3,
    name: "农服",
  },
  {
    code: 4,
    name: "农技",
  },
  // {
  //   code: 5,
  //   name: "其他",
  // },
];



export default {
  components: {
    Card,
    leftDetail,
    rightDetail,
  },
  watch: {
    currentRight: function () {
      let key = this.currentRight;
      this.getPurchaseInfoOfAll(key);
    },
    currentLeft: function () {
      let key = this.currentLeft;
      this.getSupplyInfoOfAll(key);
    },
    topSelectAreacode: function () {
      this.getSupplyInfoOfAll(this.currentLeft);
      this.getPurchaseInfoOfAll(this.currentRight);
    },
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
  },
  data() {
    return {
      showDetail2: false,
      showDetail: false,
      currentItem: "",
      dialogVisible: false,
      otherVisible: false,
      serveVisible: false,
      agriVisible: false,
      demandVisible: false,
      globalUrl: `${window.globalUrl.HOME_API_WEI}`,
      leftTypeList,
      currentLeft: 1,
      currentRight: 1,
      params: {
        psize: 4,
        pnum: 1,
      },
      purcharseData: [],
      supplyData: [],
      rightMore: true,
    };
  },

  mounted() {
    this.getSupplyInfoOfAll(1);
    this.getPurchaseInfoOfAll(1);
  },

  methods: {
    closeDialog1() {
      this.showDetail = false;
    },
    closeDialog2() {
      this.showDetail2 = false;
    },
    detailClick(val) {
      this.showDetail = true;
      this.$nextTick(() => {
        this.$refs.showDetail.init(val);
        console.log("val", val);
      });
      console.log(val, "进行");
    },
    detailClick2(val) {
      console.log("val", val);
      this.showDetail2 = true;
      this.$nextTick(() => {
        this.$refs.showDetail2.init(val, "求购信息详情");
      });
    },
    showDetails(item, type) {
      if (type == 1) {
        this.dialogVisible = true;
      } else if (type == 2) {
        this.otherVisible = true;
      } else if (type == 3) {
        this.serveVisible = true;
      } else if (type == 4) {
        this.agriVisible = true;
      } else if (type == 5) {
        this.demandVisible = true;
      }

      this.currentItem = item;
    },

    goMore() {
      if (this.currentLeft == 1) {
        this.$router.push({ name: "landTrust", query: { currentCode: 1 } });
      } else if (this.currentLeft == 2) {
        this.$router.push({ name: "agriService" });
      } else if (this.currentLeft == 3) {
        this.$router.push({ name: "machineryService" });
      } else if (this.currentLeft == 4) {
        this.$router.push({ name: "technologyService" });
      }
    },

    goMoreRight() {
      this.$router.push({ name: "landTrust", query: { currentCode: 2 } });
    },

    //供应信息
    async getSupplyInfoOfAll(key) {
      let res = null;
      this.supplyData = [];
      switch (key) {
        //托管
        case 1:
          res = await getCompanyList({
            ...this.params,
            ...{
              areaCode: this.topSelectAreacode ? this.topSelectAreacode : "",
            },
          });
          // res.setHeader();
          if (res.dataNum > 0) {
            res.data.forEach((item) => {
              if (item.orderType == "1") {
                item.orderTypeName = "全程托管";
              } else {
                item.orderTypeName = "环节托管";
              }
              item.title =
                item.intentionRegion +
                (item.serviceType == 1 ? "全程托管" : "环节托管") +
                (item.serviceArea ? item.serviceArea : 0) +
                "亩" +
                item.serviceScope;
              item.dayNum = item.createTime ? item.createTime.substr(8, 2) : "";
              item.yearNum = item.createTime
                ? item.createTime.substr(0, 4)
                : "";
              item.monthNum = item.createTime
                ? item.createTime.substr(5, 2)
                : "";
            });
            this.supplyData = res.data;
            console.log(res.data, "查看全部的数据");
          } else {
            this.supplyData = [];
          }
          break;
        //农资
        case 2:
          res = await getSupplyInfoOfMaterials(this.params);
          if (res.status && res.dataNum > 0) {
            this.supplyData = res.data;
            this.supplyData.forEach((item) => {
              if (item.businessType == "1") {
                item.businessTypeName = "种子商店";
              } else if (item.businessType == "2") {
                item.businessTypeName = "农药商店";
              } else {
                item.businessTypeName = "化肥商店";
              }

              item.dayNum = item.registrationDate
                ? item.registrationDate.substr(8, 2)
                : "";
              item.yearNum = item.registrationDate
                ? item.registrationDate.substr(0, 4)
                : "";
              item.monthNum = item.registrationDate
                ? item.registrationDate.substr(5, 2)
                : "";
            });
          } else {
            this.supplyData = [];
          }
          break;
        //农服
        case 3:
          res = await getSupplyInfoOfService(this.params);
          if (res.dataNum > 0) {
            this.supplyData = res.data;
            this.supplyData.forEach((item) => {
              if (item.businessType == "1") {
                item.businessTypeName = "种子商店";
              } else if (item.businessType == "2") {
                item.businessTypeName = "农药商店";
              } else {
                item.businessTypeName = "化肥商店";
              }
            });
          } else {
            this.supplyData = [];
          }
          break;
        // 农技
        case 4:
          res = await getSupplyInfoOfTechnology(this.params);
          if (res.dataNum > 0) {
            this.supplyData = res.data;
            this.supplyData.forEach((item) => {
              item.dayNum = item.updateTime ? item.updateTime.substr(8, 2) : "";
              item.yearNum = item.updateTime
                ? item.updateTime.substr(0, 4)
                : "";
              item.monthNum = item.updateTime
                ? item.updateTime.substr(5, 2)
                : "";
            });
            console.log(this.supplyData);
          } else {
            this.supplyData = [];
          }
          break;
        //其他
        case 5:
          this.leftMore = false;
          break;
      }
    },
    //求购信息
    async getPurchaseInfoOfAll(key) {
      let res = null;
      switch (key) {
        //托管
        case 1:
          this.rightMore = true;
          res = await getOrderList({
            ...this.params,
            ...{ areaCode: this.topSelectAreacode },
          });
          break;
        //农资
        case 2:
          this.rightMore = false;
          break;
        //农服
        case 3:
          this.rightMore = false;
          break;
        // 农技
        case 4:
          this.rightMore = false;
          break;
        //其他
        case 5:
          this.rightMore = false;
          break;
      }
      if (res) {
        if (res.status == true) {
          this.purcharseData = res.data;
          console.log("res1111", res.data);
          this.purcharseData.forEach((item) => {
            if (item.orderType == "1") {
              item.orderTypeName = "全程托管";
            } else {
              item.orderTypeName = "环节托管";
            }
            item.dayNum = item.startDate ? item.startDate.substr(8, 2) : "";
            item.yearNum = item.startDate ? item.startDate.substr(0, 4) : "";
            item.monthNum = item.startDate ? item.startDate.substr(5, 2) : "";
          });
        } else {
          this.purcharseData = [];
        }
      }
    },
  },

};
</script>

<style lang="less" scoped>
.img {
  width: 400px;
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}

.supply-demand {
  display: flex;

  > div {
    flex: 1;
  }

  .supply {
    background: #f1f3f5;
    color: #fff;
    width: 50%;
    .title {
      height: 48px;
      line-height: 48px;
      display: flex;

      h5 {
        padding: 0;
        margin: 0;
        width: 186px;
        background: url("../../../assets/bg-title.png") no-repeat;
        font-size: 18px;
        padding-left: 26px;
        background-size: cover;
      }

      .more {
        flex: 1;
        text-align: right;
        padding-right: 15px;
        color: #666;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          color: #0668b3;
        }
      }
    }

    .new-type {
      margin: 11px 0;
      display: flex;

      li {
        color: #333;
        font-size: 16px;
        margin: 0 20px;
        padding: 0px 10px;
        border-radius: 30px;
        cursor: pointer;

        &.active {
          background: #67c23a;
          color: #fff;
        }
      }
    }

    .new-des {
      padding: 0px 15px;
      height: 291px;
      .workStyle {
        font-size: 16px;
        color: #666;
        font-weight: bold;
        margin-left: 24px;
      }

      .workStyle2 {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        margin-left: 24px;
      }
      li {
        border-bottom: dashed 1px rgba(112, 112, 112, 0.3);
        display: flex;
        padding: 10px 0;

        &:last-child {
          border-bottom: 0;
        }
        .new-date {
          background: #9caba9;
          text-align: center;
          color: #fff;
          width: 56px;
          height: 52px;
          padding: 0 5px;
          margin-right: 15px;
          font-family: "Arial";
          span {
            font-size: 30px;
          }
          p {
            font-size: 12px;
            margin: 0;
            padding: 0;
          }
        }

        .new-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          flex: 1;
          > span {
            margin-top: 5px;
            font-size: 16px;
            color: #666;
            font-weight: bold;
          }
          .addressAndName {
            display: flex;
            justify-content: space-between;

            .address {
              margin-right: 24px;
            }
          }

          p {
            color: rgba(102, 102, 102, 0.6);
            margin-top: 5px;
            margin-bottom: 0;
            padding: 0;
            width: 100%;
            display: flex;

            > span {
              flex: 1;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .demand {
    background: url("../../../assets/bg.png") no-repeat;
    background-size: 100%;
    .title {
      .more {
        color: rgba(255, 255, 255, 0.75);

        &:hover {
          color: #fff;
        }
      }
    }

    .new-type {
      li {
        color: #fff;
      }
    }

    .new-des {
      li {
        .new-date {
          background: rgba(255, 255, 255, 0.5);
        }

        .new-title {
          > span {
            color: #fff;
          }
          p {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }
}
</style>
