<template>
  <card title="土地托管统计">
    <div class="landSta">
      <div class="landSta-top">
        <TopLeft />
        <TopRight />
      </div>
      <div class="landSta-bottom">
        <BottomLeft/>
        <BottomCenter/>
        <BottomRight/>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";
import TopLeft from "./landTrustSubpage/topLeft.vue";
import TopRight from "./landTrustSubpage/topRight.vue";
import BottomLeft from './landTrustSubpage/bottomLeft.vue';
import BottomCenter from './landTrustSubpage/bottomCenter.vue';
import BottomRight from './landTrustSubpage/bottomRight.vue';
export default {
  components: {
    Card,
    TopLeft,
    TopRight,
    BottomLeft,
    BottomCenter,
    BottomRight
  },
};
</script>

<style lang="less" scoped>
.landSta {
  height: 724px;
  display: grid;
  grid-template-rows: 376px 332px;
  gap: 16px;

  &-top{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  &-bottom{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}
</style>
