<template>
  <div class="production">
    <div class="allShrink">
      <div class="header-banner">
        <div class="login-area" v-if="!showLogin">
          <login-area></login-area>
        </div>
        <div class="swiper">
          <el-carousel
            class="carousel"
            :interval="4000"
            :loop="true"
            arrow="always"
            height="500px"
          >
            <el-carousel-item>
              <!-- <p class="slogan">以农为本 为农服务 助农增收</p> -->
              <img src="../../assets/imgs/banner2.png" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="../../assets/imgs/banner2.png" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 快速入口 -->
      <p-quick-link></p-quick-link>
      <!-- 供求资讯 -->
      <supply-demand></supply-demand>

      <!-- 成果展示 -->
      <achieve-display></achieve-display>

      <!-- 生产服务动态 -->
      <!-- <production-performance></production-performance> -->

      <!-- 土地托管统计 -->
      <landtrust-statistics></landtrust-statistics>
    </div>
  </div>
</template>

<script>
import { getStore } from "@/js/utils/store";
import LoginArea from "@/components/LoginArea";
import PQuickLink from "./components/PQuickLink.vue";
import AchieveDisplay from "./components/AchieveDisplay.vue";
import SupplyDemand from "../homepage/components/SupplyDemand.vue";
import LandtrustStatistics from "./components/LandtrustStatistics.vue";
import ProductionPerformance from "./components/ProductionPerformance.vue";

export default {
  components: {
    LoginArea,
    PQuickLink,
    SupplyDemand,
    AchieveDisplay,
    LandtrustStatistics,
    ProductionPerformance,
  },

  data() {
    return {
      showLogin: getStore({ name: "access_token" }),
    };
  },
};
</script>

<style lang="less" scoped>
.production {
  text-align: left;
  background: #fff;
  display: grid;
  .allShrink{
    width: 90%;
    margin: auto;
  }
}
</style>
