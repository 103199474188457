<template>
  <card title="快速入口">
    <ul class="quick-link">
      <li
        :class="item.code"
        v-for="item of linkList"
        :key="item.code"
        @click="linkHandle(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";
import { log } from "console";
import { getStore } from "@/js/utils/store.js";
import { mapGetters, mapMutations } from "vuex";
// import {  } from "";

const linkList = [
  {
    code: "one",
    name: "土地托管",
    path: "landTrust",
  },
  {
    code: "two",
    name: "农资服务",
    path: "agriService",
  },
  {
    code: "three",
    name: "农机服务",
    path: "machineryService",
  },
  {
    code: "four",
    name: "农技服务",
    path: "technologyService",
  },
  {
    code: "five",
    name: "涉农补贴",
    path: "AgricultureRelatedSubsidies",
  },
  {
    code: "six",
    name: "会计服务",
    path: "accountingService",
  },
  {
    code: "seven",
    name: "农友圈",
    path: "peasantCircle",
  },
];
export default {
  components: {
    Card,
  },

  data() {
    return {
      linkList,
    };
  },
  computed: {
    ...mapGetters(["vipLeftTree"]),
  },

  methods: {
    linkHandle(item) {
      console.log(item); // 获取点击过后带来的数据
      let token = getStore({ name: "access_token" }); // 获取tocke
      if (item.name == "会计服务") {
        // 判断名字是否一样
        if (token && item.name == "会计服务") {
          console.log(token);
          //如果登录了，就跳转到免费记账页面
          let arr = this.vipLeftTree;

          arr.forEach((item) => {
            console.log("父:", item);
            item.children.forEach((child) => {
              console.log("子:", child);
              if (
                child.allPath ===
                "/memberCenter/freeAccounting/AccountStatement"
                // "/memberCenter/tggl/wytg"
              ) {
                // 执行路由跳转
                this.$router.push({
                  path: child.allPath,
                });
              }
            });
          });

          console.log("arr", arr);
          // 下面是跳转到动态路由里面
          // if (arr.length > 1) {
          //   if (arr[1].children.length == 0) {
          //     this.$router.push({
          //       path: arr[1].allPath,
          //     });
          //   } else {
          //     if (arr[1].children.length > 0) {
          //       this.$router.push({
          //         path: arr[1].children[0].allPath,
          //       });
          //     }
          //   }
          // }
        } else {
          this.$router.push({ name: "login" }); // 没有登录就跳转到登录页
        }
      } else {
        this.$router.push({ name: item.path });
      }
    },
  },
};
</script>

<style lang="less" scoped>
ul li {
  list-style: none;
}
.quick-link {
  margin: 0;
  padding: 0;
  display: flex;

  li {
    list-style: none;
    margin: 0 6px;
    padding: 105px 0px 30px 0;

    flex: 1;
    background: #fafafa;
    border: 1px solid #e5e5e5;
    text-align: center;
    font-size: 24px;
    color: #333;
    cursor: pointer;

    &:hover {
      color: #fff;
      transition: 0.3s;
    }

    &.one {
      background: url("../../../assets/icon-q-d-1.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-1.png") no-repeat center 30px
          #0668b3;
      }
    }

    &.two {
      background: url("../../../assets/icon-q-d-2.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-2.png") no-repeat center 30px
          #0668b3;
      }
    }

    &.three {
      background: url("../../../assets/icon-q-d-3.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-3.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.four {
      background: url("../../../assets/icon-q-d-4.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-4.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.five {
      background: url("../../../assets/icon-q-d-5.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-5.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.six {
      background: url("../../../assets/icon-q-d-6.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-6.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.seven {
      background: url("../../../assets/icon-q-d-7.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-7.png") no-repeat center 30px
          #0668b3;
      }
    }
  }
}
</style>
